import React, { useEffect } from "react";
import { connect } from "react-redux";
import {NftCards} from "../nftCards";
import PolyientHeading from "../polyientHeading";
import { getBlueChipNFTMarketVolume } from "../../services/actions/api";
import {convertToInternationalCurrencySystem, currencyStrFromNum} from "../../utils/index";

const SalesVolume = (props) => {
  const { page, blueChipNFTMarketVolume, totalNFTMarketVolume } = props;
  let bluechip_market_value = {};
  let marketValue = {}
  useEffect(() => {
    props.getBlueChipNFTMarketVolume();
  }, []);

  if (totalNFTMarketVolume.data && totalNFTMarketVolume.data.latest) {
    let differenceVal = totalNFTMarketVolume.data.latest.usd - totalNFTMarketVolume.data.oldest.usd;
    let diffStr = convertToInternationalCurrencySystem(differenceVal);
    let diffstrval = currencyStrFromNum(differenceVal);

    

    marketValue = {
      head:"All Collection",
      number: parseFloat(diffStr),
      icon: totalNFTMarketVolume.data.percentage_change < 0 ? <i className="fa-solid fa-arrow-trend-down px-2 "></i> : <i className="fa-solid fa-arrow-trend-up px-2 "></i>,
      percentage: totalNFTMarketVolume.data.percentage_change.toFixed(2),
      caption1:`${diffstrval} USD`,
      caption2:"vs last 30 days",
    };
  }

  if(blueChipNFTMarketVolume.data && blueChipNFTMarketVolume.data.latest){
    let strnum = blueChipNFTMarketVolume.data.latest.usd;
    
    let strnumval = currencyStrFromNum(strnum);
    bluechip_market_value = {
      head:"Blue Chip Collections",
      number: parseFloat(strnum).toLocaleString('en-US', {maximumFractionDigits:2}),
      icon: blueChipNFTMarketVolume.data.percentage_change < 0 ? <i className="fa-solid fa-arrow-trend-down px-2 "></i> : <i className="fa-solid fa-arrow-trend-up px-2 "></i>,  
      percentage: blueChipNFTMarketVolume.data.percentage_change.toFixed(2),
      caption1:`${strnumval} USD`,
      caption2:"vs last 30 days",
    }
  }

  
  

  const heading=[{
    head:"Sales Volume",
    para:"Total NFT sales volume for NFT collections."
  }]

  return(
    <>
      <section>
        <div className="">
          <div className="mt-4">
            {heading.map((table,index) => <PolyientHeading key={index}  head={table.head} para={table.para}/>)}
          </div>
          <div className="row">
            <NftCards key="All Collections" card_detail={marketValue} />

            <NftCards key="Blue Chip Collections" card_detail={bluechip_market_value} />
          </div>
        </div>
      </section>
    </>
  )
}


const mapStateToProps = (state) => ({
  page: state.page,
  blueChipNFTMarketVolume: state.blueChipNFTMarketVolume,
  totalNFTMarketVolume: state.totalNFTMarketVolume
})

const mapDispatchToProps = {
  getBlueChipNFTMarketVolume
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesVolume);
import React, {useEffect} from "react";
import {connect} from 'react-redux';
import Collection from "../../components/collectionStatus";
import { getCollectorCountData,getNFTData,getSeriesCountData,getHolderValuesData } from './../../services/actions/api';
import { useParams } from "react-router-dom";

const CollectionStatus = (props) =>{
  const params = useParams()
    useEffect(()=>{
        // props.getTotalNFTMarketVolume30days();
        props.getNFTData(params.contractAddress);
        props.getCollectorCountData(params.contractAddress);
        props.getSeriesCountData(params.contractAddress);
        props.getHolderValuesData(params.contractAddress);
      },[]);
    return(
      <>
        <Collection/>
      </>
    )
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  getCollectorCountData,
  getNFTData,
  getSeriesCountData,
  getHolderValuesData
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionStatus);
import React from "react";
import Walletactivity from "../../components/walletActivity";

function WalletActivity(){
    return(
        <>
        <Walletactivity/>
        </>
    )
}
export default WalletActivity;
import { combineReducers } from 'redux';

import {pageAreaReducer, blueChipNFTMarketVolumeReducer, totalNFTMarketVolume30daysReducer, blueChipFloorsReducer, topVolumeNFTProjectsReducer,nftDataReducer} from './list';

const rootReducer = combineReducers({
    page: pageAreaReducer,
    blueChipNFTMarketVolume: blueChipNFTMarketVolumeReducer,
    totalNFTMarketVolume: totalNFTMarketVolume30daysReducer,
    blueChipFloors: blueChipFloorsReducer,
    topVolumeNFTProjects: topVolumeNFTProjectsReducer,
    nftData: nftDataReducer
});

export default rootReducer;
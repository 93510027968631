import React from "react";
import {Container, Navbar, Nav , NavDropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import { useLocation } from 'react-router-dom'

function Header(){
  const location = useLocation();
  return(
    <>
      <Navbar className="py-4 " bg="dark" expand="lg">
        <Container className="nav-container">
        <Link to="/" className="navbar-brand">
          <img alt="PolyientX" src="/images/logo.png" width="140px" height="100%" className="d-inline-block align-top"/>{' '}
        </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Link className={location.pathname == '/' ? "nav-link active padding1" : "nav-link padding1"} to="/">Market Overview</Link>
              <Link className={location.pathname == '/collections' ? "nav-link active" : "nav-link"} to='/collections' >Collection Stats</Link>
              {/* <Link className="nav-link" to='/collection-status' >Collection Status</Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <section className="report">
        <div className="container ">
          <div className="text-center">
            <span className="report-text-1">Enjoying this data ? </span>
            <span className="report-text-2">Contact us for a custom report.</span>
          </div>
        </div>
      </section>
    </>
  );
}
export default Header;
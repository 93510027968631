import React from "react";

function PolyientHeading(props){
    return(
        <div>
            <h1 className="nft-heading">{props.head}</h1>
            <h6 className="nft-para">{props.para}</h6> 
        </div>
    )
}
export default PolyientHeading;
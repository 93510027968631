import React, {Suspense,StrictMode} from "react";

import { Provider } from "react-redux";
import configureStore from "./services/configureStore";

import Routes from "./routes"
import './style/style.css';
import { stopReportingRuntimeErrors } from "react-error-overlay";


const store = configureStore();
// import ReactGA from "react-ga";

if (process.env.REACT_APP_NODE_ENV =="production" ) {
  stopReportingRuntimeErrors(); // disables error overlays
}

function App() {
  return (
    <StrictMode>
      <Provider store={store}>
        <Suspense fallback={null}>
          <Routes/>
        </Suspense>
      </Provider>
    </StrictMode>
  );
}

export default App;

import React, { useEffect } from "react";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  scaleShowLabels: false,
  responsive: true,
  indexAxis: 'y',
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: function(context) {
          return `${context.raw}%`;
        }
      }
    }
  },
  scales: {
    x: {
      beginAtZero: true,
      max: 100,
      
    },
  },
};


function Croakz(props){
  let gradienta, gradientb, gradientc, gradientd;
  const setGradient = () => {
    const ctx = document.getElementsByTagName("canvas")[0].getContext("2d");
    gradienta = ctx.createLinearGradient(0, 0, 400, 0);
    gradienta.addColorStop(0, 'rgba(0, 209, 222, 0.25)');
    gradienta.addColorStop(1, 'rgba(0, 209, 222, 1)');

    gradientb = ctx.createLinearGradient(0, 0, 400, 0);
    gradientb.addColorStop(0, 'rgba(236, 231, 91, 0.25)');
    gradientb.addColorStop(1, 'rgba(236, 231, 91, 1)');

    gradientc = ctx.createLinearGradient(0, 0, 400, 0);
    gradientc.addColorStop(0, 'rgba(146, 72, 248, 0.25)');
    gradientc.addColorStop(1, 'rgba(146, 72, 248, 1)');

    gradientd = ctx.createLinearGradient(0, 0, 400, 0);
    gradientd.addColorStop(0, 'rgba(0, 0, 0, 0.25)');
    gradientd.addColorStop(1, 'rgba(0, 0, 0, 1)');
  }
  useEffect(() => {
    setGradient();
    
  }, []);
  // const gradienta = ["rgba(0, 209, 222, 0.6)", "rgba(0, 209, 222, 0.80)", "rgba(0, 209, 222, 0.90)", "rgba(0, 209, 222, 1)"];
  // const gradientb = ["rgba(236, 231, 91, 0.25)", "rgba(236, 231, 91, 1)"];
  // const gradientc = ["rgba(146, 72, 248, 0.25)", "rgba(146, 72, 248, 1)"];
  // const gradientd = ["rgba(0, 0, 0, 0.25)", "rgb(0, 0, 0)"];
  let all_collection_cnt = props.collectorBehavior.reduce((total, obj) => parseInt(obj.count) + total,0);
  let labelsData;
  let labelCount;
  if(props.collectorBehavior){
    labelsData = props.collectorBehavior.map(item => item.category);
    labelCount = props.collectorBehavior.map(item => ((parseInt(item.count) / all_collection_cnt) * 100).toFixed(2));
  }
  const data = {
    labels: labelsData,
    datasets: [{
      axis: 'y',
      data: labelCount,
      fill: true,
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;
        let gradienta = ctx.createLinearGradient(0, 0, 400, 0);
        gradienta.addColorStop(0, 'rgba(0, 209, 222, 0.25)');
        gradienta.addColorStop(1, 'rgba(0, 209, 222, 1)');

        let gradientb = ctx.createLinearGradient(0, 0, 400, 0);
        gradientb.addColorStop(0, 'rgba(236, 231, 91, 0.25)');
        gradientb.addColorStop(1, 'rgba(236, 231, 91, 1)');

        let gradientc = ctx.createLinearGradient(0, 0, 400, 0);
        gradientc.addColorStop(0, 'rgba(146, 72, 248, 0.25)');
        gradientc.addColorStop(1, 'rgba(146, 72, 248, 1)');

        let gradientd = ctx.createLinearGradient(0, 0, 400, 0);
        gradientd.addColorStop(0, 'rgba(0, 0, 0, 0.25)');
        gradientd.addColorStop(1, 'rgba(0, 0, 0, 1)');
        return [gradienta, gradientb, gradientc, gradientd];
      },
      borderWidth: 0,
      
    }]
  };

  // console.log('XX',props.nftData.image_url)

  
  return(
    <>
      <section>
        <div className="">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-7">
                  {props.nftData.image_url ?
                  <img src={props.nftData.image_url} className="img-fluid rounded" alt="" />
                  :
                  <img src="/images/placeholder-image.png" className="img-fluid rounded" alt="" />
                  } 
                </div>
                <div className="col-5">
                  <h5 className="table-subtitle">Collectors</h5>
                  <h1 className="number">{props.nftData.num_owners}</h1>
                  <h5 className="table-subtitle">Floor Price</h5>
                  <h1 className="number">{props.nftData.floor_price_eth}</h1>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h5 className="collector-behavior">Collector Behavior</h5>
          
              <Bar id="barchartd" fill="#00FFFF" data={data} options={options} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Croakz;
import React, { useEffect } from "react";
import {connect} from 'react-redux'
import PolyientHeading from "../polyientHeading";
import {AverageNftCards} from "../nftCards";
import { getBlueChipFloors } from '../../services/actions/api'

const TopNft = (props) => {
  const { blueChipFloors } = props;
  let lowdata={}, middata = {}, highdata ={};

  const heading=[{
    head:"Top 10 NFT Collections",
    para:"Average prices and sales volume for the top 10 NFT collections." 
  }];

  
  let blue_chip_floor = {};

  useEffect(() => {
    props.getBlueChipFloors();
  }, []);

  if (blueChipFloors.data) {
    lowdata = {
      head:"Average Floor - Low",
      number: `${blueChipFloors.data.low?.value}`,
      icon: blueChipFloors.data.low_change < 0 ? <i className="fa-solid fa-arrow-trend-down px-2 "></i> : <i className="fa-solid fa-arrow-trend-up px-2 "></i>,
      percentage:  blueChipFloors.data.low_change < 0 ? Math.floor(Math.abs(blueChipFloors.data.low_change) * 100) * -1 / 100 : blueChipFloors.data.low_change?.toFixed(2),
      caption1:"USD",
      caption2:"vs last 30 days",
      status:  'matric-delta-success'
    }

    middata = {
      head:"Average Floor - Mid",
      number: `${blueChipFloors.data.mid?.value}`,
      icon: blueChipFloors.data.mid_change < 0 ? <i className="fa-solid fa-arrow-trend-down px-2 "></i> : <i className="fa-solid fa-arrow-trend-up px-2 "></i>,
      percentage: blueChipFloors.data.mid_change < 0 ? Math.floor(Math.abs(blueChipFloors.data.mid_change) * 100) * -1 / 100 : blueChipFloors.data.mid_change?.toFixed(2),
      caption1:"USD",
      caption2:"vs last 30 days",
      status:  'matric-delta-success'
    }
    
    highdata = {
      head:"Average Floor - High",
      number: `${blueChipFloors.data.high?.value}`,
      icon: blueChipFloors.data.high_change < 0 ? <i className="fa-solid fa-arrow-trend-down px-2 "></i> : <i className="fa-solid fa-arrow-trend-up px-2 "></i>,
      percentage: blueChipFloors.data.high_change < 0 ? Math.floor(Math.abs(blueChipFloors.data.high_change) * 100) * -1 / 100 : blueChipFloors.data.high_change?.toFixed(2),
      caption1:"USD",
      caption2:"vs last 30 days",
      status:  'matric-delta-success'
    } 
  }
  return(
    <>
      <section>
        <div className="mt-5">
          {heading.map((table,index) => <PolyientHeading key={index}  head={table.head} para={table.para}/>)}
        </div>
        <div className="row">
          <h5 className="table-subtitle pt-4">Average Floor Prices</h5>
          
          <AverageNftCards key={'low'} card_detail={lowdata}/>
          <AverageNftCards key={'mid'} card_detail={middata}/>
          <AverageNftCards key={'high'} card_detail={highdata}/>
        </div>
      </section>      
    </>
  )
}

const mapStateToProps = (state) => ({
  page: state.page,
  blueChipFloors: state.blueChipFloors
})

const mapDispatchToProps = {
  getBlueChipFloors
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNft);
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MarketOverview from './../../components/marketOverview';
import { getTotalNFTMarketVolume30days } from './../../services/actions/api';

const Home = (props) =>{
  useEffect(()=>{
    props.getTotalNFTMarketVolume30days();
  },[]);
  return(
    <>
      <MarketOverview/>
    </>
  )
}
const mapStateToProps = (state) => ({
})

const mapDispatchToProps = {
  getTotalNFTMarketVolume30days
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
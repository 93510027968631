import React from "react";
import WalletVolume from "../walletsVolume";
import WalletValue from "../walletValue";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
function Walletactivity(){
    return(
        <>
        <section className="mt-5">
            <div className="container nav-container">
            <h1 className="market-overview-head">NFT Collection Stats</h1>
                <h5 className="sales-performance">Performance stats for select NFT collections.</h5>
                <div className="d-flex">
                <div className="my-2 me-2">
                    <h1 className="badge last-days py-2 px-2">Last 30 days: 5/14/22 - 6/14/22</h1>
                </div>
                <div className="my-2 mx-2">
                    <h1 className="badge last-days py-2 px-2">Ethereum</h1>
                </div>
                </div>
                {/* <ActiveWallet/> */}
                <Tabs
                  defaultActiveKey="topCollections"
                  id="uncontrolled-tab-example"
                  className="mb-3"
                >
                  <Tab eventKey="topCollections" title="Top 10 Collection">
                    <WalletVolume/>
                  </Tab>
                </Tabs>    
            </div>
        </section>
        </>
    )
}
export default Walletactivity;
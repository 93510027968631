import React from "react";

function Footer (){
  return(
    <>
    <section className="bg-dark mt-5 ">
      <div className="container nav-container pb-5">
        <h1 className="footer-head padding-top">Contact Us</h1>
        <p className="footer-para">Whether you're a creator looking to take your roadmap to the next level, or a brand wanting help with your NFT strategy, PolyientX can help.</p>
        <div className="row padding-top">
          <div className="col-lg-4 my-2">
            <h2 className="footer-content">Talk to an expert</h2>
            <div>
              <a href="https://calendly.com/d/dh9-3dg-d6n/polyientx-intro" target="_blank" rel="noreferrer" className="btn book-btn px-4 py-2 mt-3">Book a call with our team</a>
            </div>
          </div>
          <div className="col-lg-4 my-2">
            <h2 className="footer-content">Connect with us</h2>
            <div className="mt-4">
              <a href="https://discord.com/invite/fXgVxdp" target="_blank" rel="noreferrer"><i className="fa-brands fa-discord text-white fa-2x mx-2"></i></a>
              <a href="https://twitter.com/polyientx" target="_blank" rel="noreferrer"><i className="fa-brands fa-twitter text-white fa-2x mx-2"></i></a>
              <a href="mailto:hello@polyientx.com" target="_blank" rel="noreferrer"><i className="fa-solid fa-envelope text-white fa-2x mx-2"></i></a>
            </div>
          </div>
          <div className="col-lg-4 my-2">
          </div>
        </div>
        <div className="padding-top">
          <img src="/images/footer-logo.svg" alt="PolyientX" className="img-fluid footer-logo"/>
        </div>
        <div className="row padding-top align-items-center">
          <div className="col-lg-4">
            <h5 className="footer-credit mb-0 pb-0">© Copyright 2022 Polyient, Inc.</h5>
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-4">
                <h5><a href="https://www.iubenda.com/privacy-policy/62786231" className="footer-credit" target="_blank" rel="noreferrer">Privacy Policy</a></h5>
              </div>
              <div className="col-lg-4">
                <h5><a href="https://www.iubenda.com/privacy-policy/62786231/cookie-policy" className="footer-credit" target="_blank" rel="noreferrer">Cookies Settings</a></h5>
              </div>
              <div className="col-lg-4">
                <h5><a href="https://www.iubenda.com/terms-and-conditions/62786231" className="footer-credit" target="_blank" rel="noreferrer">Terms and Conditions</a></h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
export default Footer;
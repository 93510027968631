import React from "react";
import NftCollection from "../nftCollection";
import SalesActivity from "../salesActivity";
import TopNft from "../topNft";
import SalesVolume from "../salesVolume";

function MarketOverview(){
   
    return(
        <>
        <section className="mt-5">
            <div className="container nav-container">
                <h1 className="market-overview-head">Market Overview</h1>
                <h5 className="sales-performance">Performance snapshot of the NFT market.</h5>
                <div className="d-flex">
                <div className="my-2 me-2">
                    <h1 className="badge last-days py-2 px-2">Last 30 days: 5/14/22 - 6/14/22</h1>
                </div>
                <div className="my-2 mx-2">
                    <h1 className="badge last-days py-2 px-2">Ethereum</h1>
                </div>
                </div>
                <SalesVolume/>
                <SalesActivity/> 
                <TopNft/>   
                <NftCollection/>
            </div>
        </section>
        </>
    )
}
export default MarketOverview;
import React,{useEffect} from "react";
import { connect } from "react-redux";
import {Routes,Route,BrowserRouter, Navigate} from "react-router-dom";
import { useLocation} from 'react-router-dom'
import {LayoutOne} from "../layouts"
import Home from "./../pages/home";
import WalletActivity from "../pages/walletActivity";
import CollectionStatus from "../pages/collectionStatus";

import { setPageArea } from "../services/actions/api";


function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop =(_ScrollToTop)

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Layout {...rest} page={rest.page}>
      <Component {...rest} />
    </Layout>
  );
}

export const RoutesObj = (props) => {
  const {user} = props;

  useEffect(()=>{
    props.setPageArea();
    window.onresize = props.setPageArea;
    return ()=>{
      window.onresize = null;
    }
  },[window.innerWidth]);

  return (
    <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <>
          <Route exact path="/" element={<RouteWrapper exact component={Home} layout={LayoutOne} {...props}/> } />
          <Route exact path="/collections" element={<RouteWrapper exact component={WalletActivity} layout={LayoutOne} {...props}/> } />
          <Route exact path="/collection-status/:contractAddress" element={<RouteWrapper exact component={CollectionStatus} layout={LayoutOne} {...props}/> } />
          <Route  
            path="*"
            element={<Navigate to="/" replace />}
          />
        </>
      </Routes>
    </ScrollToTop>
    </BrowserRouter>
  )
}

const mapStateToProps = (state) => ({
  page: state.page
})

const mapDispatchToProps = {
  setPageArea
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesObj);
import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

export function setPageArea(){
  return async dispatch => {
    dispatch({
      type: "PAGE_SET_AREA",
      data:{
        width: window.innerWidth,
        height: window.innerHeight
      }
    }) 
  }
}

export function getTotalNFTMarketVolume30days(){
  return async dispatch => {
    dispatch({
      type: "GET_TOTAL_NFT_MARKET_VOLUME_REQUEST",
      data:{}
    })

    try {
      const response = await axios.get("/market/totalNFTMarketVolume30days/get");
      dispatch({
        type: "GET_TOTAL_NFT_MARKET_VOLUME_SUCCESS",
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: "GET_TOTAL_NFT_MARKET_VOLUME_ERROR",
        data: {},
        message: error.message
      })
    }
  }
}

export function getBlueChipNFTMarketVolume(){
  return async dispatch => {
    dispatch({
      type: "GET_NFT_MARKET_VOLUME_REQUEST",
      data:{}
    })

    try {
      const response = await axios.get("/market/blueChipNFTMarketVolume/get");
      dispatch({
        type: "GET_NFT_MARKET_VOLUME_SUCCESS",
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: "GET_NFT_MARKET_VOLUME_ERROR",
        data: {},
        message: error.message
      })
    }
  }
}

export function getBlueChipFloors(){
  return async dispatch => {
    dispatch({
      type: "GET_BLUE_CHIP_FLOORS_REQUEST",
      data:{}
    })

    try {
      const response = await axios.get("/market/blueChipFloors/get");
      dispatch({
        type: "GET_BLUE_CHIP_FLOORS_SUCCESS",
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: "GET_BLUE_CHIP_FLOORS_ERROR",
        data: {},
        message: error.message
      })
    }
  }
}


export function getTopVolumeNFTProjects(){
  return async dispatch => {
    dispatch({
      type: "GET_TOP_VOLUME_NFT_PROJECTS_REQUEST",
      data:{}
    })

    try {
      const response = await axios.get("/market/topVolumeNFTProjects/get");
      dispatch({
        type: "GET_TOP_VOLUME_NFT_PROJECTS_SUCCESS",
        data: response.data
      })
    } catch (error) {
      dispatch({
        type: "GET_TOP_VOLUME_NFT_PROJECTS_ERROR",
        data: {},
        message: error.message
      })
    }
  }
}

export function getNFTData (contractAddress) {
  return async dispatch => {
    try{
      const response = await axios.get(`/market/collectionBasicInfo/get/${contractAddress}`);
      const collectorBehavior = await axios.get(`/market/categoryData/get/${contractAddress}`);
      dispatch({
        type: "GET_NFT_FLOOR_PRICE_SUCCESS",
        floorPrice: response.data,
        behaviour: collectorBehavior.data
      })
      

    }
    catch(e){
      dispatch({
        type: "GET_NFT_COLLECTORS_SUCCESS",
        data: {},
        message: e.message
      })
    }
  }
}

export function getCollectorCountData(contractAddress) {
  return async dispatch => {
    dispatch({
      type: "GET_NFT_COLLECTORS_REQUEST",
      data:{}
    })
    try{
      const collectorsCount = await axios.get(`/market/getNFTWalletStats/get/${contractAddress}`);
      dispatch({
        type: "GET_NFT_COLLECTORS_SUCCESS",
        collectorsCount: collectorsCount.data
      })
    }
    catch(e){
      dispatch({
        type: "GET_CROAKZ_DATA_ERROR",
        data: {},
        message: e.message
      })
    }
  }
}


export function getSeriesCountData(contractAddress) {
  return async dispatch => {
    dispatch({
      type: "GET_NFT_SERIES_COUNT_REQUEST",
      data:{}
    })
    try{
      const seriesCount = await axios.get(`/market/getNftSeriesCounts/get/${contractAddress}`);
      dispatch({
        type: "GET_NFT_SERIES_COUNT_SUCCESS",
        seriesCount: seriesCount.data
      })

    }
    catch(e){
      dispatch({
        type: "GET_CROAKZ_DATA_ERROR",
        data: {},
        message: e.message
      })
    }
  }
}


export function getHolderValuesData (contractAddress) {
  return async dispatch => {
    try{
      const holderValues = await axios.get(`/market/getUSDBalanceOfWallets/get/${contractAddress}`);
      dispatch({
        type: "GET_NFT_HOLDER_VALUES_SUCCESS",
        holderValues: holderValues.data
      })
    }
    catch(e){
      dispatch({
        type: "GET_NFT_HOLDER_VALUES_SUCCESS",
        data: {},
        message: e.message
      })
    }
  }
}
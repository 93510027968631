import React from "react";
import PolyientHeading from "../polyientHeading";
import {Table} from "react-bootstrap";
import CollectionTable from "../../components/collectionTable";

function CollectionHeld(props){
  const heading=[{
    head:"Other Collections Held",
    para:"What other NFT collections are theses collectors holding?"
  }]
  return(
    <>
    <section>
                <div className="mt-5">
                {heading.map((table,index) => <PolyientHeading key={index}  head={table.head} para={table.para}/>)}
                  {/* <h5 className="collector-behavior">Other Collerctions Held</h5>
                  <p className="nft-para">What other NFT collections are theses collectors holding?</p> */}
                </div>
                <Table className=""  hover  responsive> 
                    <thead>
                        <tr>
                        <th className="table-heading">Collection</th>
                        <th></th>
                        <th className="table-heading">NFTs Held</th>
                        </tr>
                    </thead>
                    <tbody>
                      {props.seriesCount.length > 0 ?
                    props.seriesCount.map((table,index) => <CollectionTable key={index} data3={table.nft_name} data2={table.num_tokens} />)
                    : 
                    <tr><td colSpan="3" className="text-center">No Collections found</td></tr>
                  }
                    </tbody>
                </Table>  
        </section>
    </>
  )
}
export default CollectionHeld;
import React from "react";
import CollectionHeld from "../collectionHeld";
import Croakz from "../croakz";
import NFTHolders from "../nftHolders";
import WalletHolders from "../walletHolders";
import {connect} from 'react-redux';

function Collection(props){
    const {nftData} = props;
    return(
        <>
        <section className="mt-5">
            <div className="container nav-container">
                <h1 className="market-overview-head">{nftData.data?.name ? nftData.data.name : ''}</h1>
                <h5 className="sales-performance">Collection stats for the {nftData.data?.name ? `${nftData.data.name}` : ''} NFT collection</h5>
                <div className="d-flex">
                <div className="my-2 me-2">
                    <h1 className="badge last-days py-2 px-2">Last 30 days: 5/14/22 - 6/14/22</h1>
                </div>
                <div className="my-2 mx-2">
                    <h1 className="badge last-days py-2 px-2">Ethereum</h1>
                </div>
                </div>
                {nftData.floor_price === 'success'&&
                <Croakz nftData={nftData.data} collectorBehavior={nftData.collectorBehavior}/>
                }
                {nftData.getCollectors === 'success' &&
                <NFTHolders collectorsCount={nftData.collectorsData}/>
                }
                {nftData.seriesCount === 'success' &&
                <CollectionHeld seriesCount={nftData.seriesCountData}/>
                }
                {nftData.holderValues === 'success' &&
                <WalletHolders walletHolder={nftData.holderValuesData}/>
                }
            </div>
        </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    nftData: state.nftData
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Collection);
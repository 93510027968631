import React from "react";
import PolyientHeading from "../polyientHeading";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  response: true,
  scales: {
    y: {
      beginAtZero: true
    },
    x: {
      type: "time",
      time: {
        unit: "day"
      }
    }
  },
  responsive: true,
  maintainAspectRatio: false
};

const SalesActivity = (props) => {
  const { totalNFTMarketVolume } = props;
  let sale_activity_data = [];

  if (totalNFTMarketVolume.data && totalNFTMarketVolume.data.latest) {
    sale_activity_data = totalNFTMarketVolume.data.data.map((item, index) => {
      return {
        x: item.tx_timestamp,
        y: item.usd
      };
    })
  }

  const heading=[{
    head:"Sales Activity",
    para:"Daily sales activity for all NFT collections."
  }];

  const data = {
    labels: [],
    datasets: [{
      label: 'Sales in USD',
      data: sale_activity_data,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1
    }]
  };

  return(
    <>
      <section>
        <div className="">
          <div className="mt-4">
            {heading.map((table,index) => <PolyientHeading key={index}  head={table.head} para={table.para}/>)}
            <div style={{"height":"200px"}}>
              <Line type="monotone" stroke="green" fill="green" data={data} options={options} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


const mapStateToProps = (state) => ({
  page: state.page,
  totalNFTMarketVolume: state.totalNFTMarketVolume
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SalesActivity);
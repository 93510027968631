import React from "react";
import { Link } from "react-router-dom";
import { formatNum } from "../../utils";

function CollectionTable(props){
    return(
        <>
                    <tr>
                    <td colSpan={2} >
                        {/* <a href="" className="table-data">{props.data1}</a> */}
                        <Link to={`/collection-status/${props.contract_address}`} className="table-data">{props.data1}</Link>
                        {props.data3}
                        </td>
                    <td className="table-data">{formatNum(parseFloat(props.data2).toFixed(2))}</td>
                    </tr>
        </>
    )
}
export default CollectionTable;
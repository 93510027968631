import React from "react";

export function NftCards(props){
    const {card_detail} = props;
    
    
    return(
        <>
        <div className="col-lg-4 ">
            <div className="card nft-card my-2">
                {
                    card_detail ?
                    <div className="card-body">
                        <h5 className="nft-card-head">{card_detail.head}
                        </h5>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="d-flex justify-content-between nft-card-number">{card_detail.number}</h5>
                            <div className={(card_detail.percentage < 0) ? 'badge text-end matric-delta-error' : 'badge text-end matric-delta-success'}>{card_detail.icon} { card_detail.icon2 }
                                {card_detail.percentage}%</div>  
                        </div>
                        <div className="d-flex justify-content-between">
                            <h5 className="nft-matric-caption">{card_detail.caption1}</h5>
                            <h5 className="nft-matric-caption">{card_detail.caption2}</h5>
                        </div>
                        
                    </div>
                    :
                    'Loading..'
                }
            </div>
        </div>
        </>
    )
}
export function AverageNftCards(props){
    const {card_detail} = props;
    
    return(
        <>
        <div className="col-lg-4 ">
            <div className="card nft-card my-2">
                {
                    card_detail ?
                    <div className="card-body">
                        <h5 className="nft-card-head">{card_detail.head}
                        </h5>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="d-flex justify-content-between nft-card-number">{parseFloat(card_detail.number).toFixed(2)}</h5>
                            <div className={(card_detail.percentage < 0) ? 'badge text-end matric-delta-error' : 'badge text-end matric-delta-success'}>{card_detail.icon} { card_detail.icon2 }
                                {card_detail.percentage}%</div>  
                        </div>
                        <div className="d-flex justify-content-between">
                            <h5 className="nft-matric-caption">{card_detail.caption1}</h5>
                            <h5 className="nft-matric-caption">{card_detail.caption2}</h5>
                        </div>
                        
                    </div>
                    :
                    'Loading..'
                }
            </div>
        </div>
        </>
    )
}

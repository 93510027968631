export function pageAreaReducer(state = {}, action){
  switch (action.type) {
    case "PAGE_SET_AREA":
      return action.data;
  }
  return state;
}

export function blueChipNFTMarketVolumeReducer(state = {}, action){
  switch (action.type) {
    case "GET_NFT_MARKET_VOLUME_REQUEST":
      return  {type:'request',data:{}}
    case "GET_NFT_MARKET_VOLUME_SUCCESS":
      return {type:'success',data:action.data}
    case "GET_NFT_MARKET_VOLUME_ERROR":
      return {type:'error', data:action.data, message:action.message}
    default:
      return state;
  }
}

export function totalNFTMarketVolume30daysReducer(state = {}, action){
  switch (action.type) {
    case "GET_TOTAL_NFT_MARKET_VOLUME_REQUEST":
      return  {type:'request',data:{}}
    case "GET_TOTAL_NFT_MARKET_VOLUME_SUCCESS":
      return {type:'success',data:action.data}
    case "GET_TOTAL_NFT_MARKET_VOLUME_ERROR":
      return {type:'error', data:action.data, message:action.message}
    default:
      return state;
  }
}

export function blueChipFloorsReducer(state = {}, action){
  switch (action.type) {
    case "GET_BLUE_CHIP_FLOORS_REQUEST":
      return  {type:'request',data:{}}
    case "GET_BLUE_CHIP_FLOORS_SUCCESS":
      return {type:'success',data:action.data}
    case "GET_BLUE_CHIP_FLOORS_ERROR":
      return {type:'error', data:action.data, message:action.message}
    default:
      return state;
  }
}

export function topVolumeNFTProjectsReducer(state = {}, action){
  switch (action.type) {
    case "GET_TOP_VOLUME_NFT_PROJECTS_REQUEST":
      return  {type:'request',data:{}}
    case "GET_TOP_VOLUME_NFT_PROJECTS_SUCCESS":
      return {type:'success',data:action.data}
    case "GET_TOP_VOLUME_NFT_PROJECTS_ERROR":
      return {type:'error', data:action.data, message:action.message}
    default:
      return state;
  }
}

export function nftDataReducer(state = {} , action){
  switch(action.type){
    case "GET_NFT_DATA_REQUEST":
      return {type:'request', data:{},floor_price:'request'}
    case "GET_NFT_FLOOR_PRICE_SUCCESS":
      return {...state,type:'success',floor_price: 'success', data:action.floorPrice, collectorBehavior:action.behaviour}
    case "GET_NFT_COLLECTORS_REQUEST":
      return {...state, getCollectors: 'request', collectorsData:{}}
    case "GET_NFT_COLLECTORS_SUCCESS":
      return {...state, getCollectors: 'success', collectorsData: action.collectorsCount}
    case "GET_NFT_SERIES_COUNT_REQUEST":
      return {...state, seriesCount: 'request', seriesCountData:{}}
    case "GET_NFT_SERIES_COUNT_SUCCESS":
      return {...state , seriesCount: 'success', seriesCountData: action.seriesCount}
    case "GET_NFT_HOLDER_VALUES_SUCCESS":
      return {...state , holderValues: 'success', holderValuesData: action.holderValues}
    default:
      return state;
  }
}
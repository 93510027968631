import React, { useState } from "react";
import {Table} from "react-bootstrap";
import CollectionTable from "../collectionTable";
import Pagination from 'react-responsive-pagination';
import PolyientHeading from "../polyientHeading";

function WalletValue(){
  const tableData=[{
    data1:"goblintown.wtf",
    data2:"1,349,500"
  },{
    data1:"Otherdeed for Otherside",
    data2:"1,139,130"
  },{
    data1:"Bored Ape Yacht Club",
    data2:"969,203"
  },{
    data1:"Mutant Ape Yacht Club",
    data2:"822,035"
  },{
    data1:"Okay Bears",
    data2:"489,019"
  },{
    data1:"Trippin' Ape Tribe ",
    data2:"384,129"
  },{
    data1:"We Are All Going to Die",
    data2:"289,112"
  },{
    data1:"CLONE X - X TAKASHI MURAKAMI",
    data2:"148,233"
  },{
    data1:"Leave Me Alone NFT",
    data2:"96,458"
  },{
    data1:"hobotown",
    data2:"50,035"
  }]
    return(
        <>
        <section>
            <div className="mt-4">
                <Table  hover  responsive>
                <thead>
                    <tr>
                    <th className="table-heading">Collection</th>
                    <th></th>
                    <th className="table-heading">Total Sales - USD</th>
                    </tr>
                </thead>
                <tbody>
                  {/* {tableData.map((table,index) => <CollectionTable key={index} data1={table.data1} data2={table.data2} />)} */}
                  <tr>
                    <td colSpan={3} align="center">No data found</td>
                  </tr>
                </tbody>
            </Table>
            </div>
        </section>
        </>
    )
}
export default WalletValue;
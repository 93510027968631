import React, {useEffect, useState} from "react";
import PolyientHeading from "../polyientHeading";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      display: true,
      barPercentage: 1.4,
      ticks: {
        max: 4,
        autoSkip: false,
      }
    },
    y: {
      display: true,
      title: {
        display: true,
        text: 'Number of Wallets'
      }
    }
  }
};



function WalletHolders(props){
  const heading=[{
    head:"Holder Wallet Values",
    para:"What is the average wallet value of holders of this NFT collection?"
  }]
  
  
  const groupBy = (arr, key) => {
    const initialValue = {};
    return arr.reduce((acc, cval) => {
      const myAttribute = parseInt(cval[key]);
      acc[myAttribute] = [...(acc[myAttribute] || []), cval]
      return acc;
    }, initialValue);
  };
  
  const grouped_keys_data = Object.keys(groupBy(props.walletHolder, "value"))

  const converToRange = (array) => {
    let arr = [];
    let arraylen = (array[array.length - 1].length - 1)
    // let arraylen = String(parseInt(array[array.length - 1] / 10)).length
    // console.log("array num =>",(array[array.length - 1] / 10))
    // console.log("array len ===>",arraylen)
    // const divBy = parseInt(`10${Array(arraylen).join(0)}`)
    const divBy = parseInt((array[array.length - 1] / 10))
    // console.log("arraylen, divBy =>")
    
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      const elm = Math.floor(element / divBy)
      const elemF = elm*divBy
      if (arr.indexOf(elemF) == -1){
        arr.push((elemF))
      }
    }

    var newArr = arr.map(x=> `${x+1}-${x+divBy}`)
    console.log("newArr =>",newArr)
    return newArr.map((a) => {
      return {nft_series: a, sum: 0}
    })
  }

  const filtered_wallet_values = (nft_series) => {
    let min = parseInt(nft_series.split("-")[0])
    let max = parseInt(nft_series.split("-")[1])
    var res = props.walletHolder.filter((d) => d.value > min && d.value <= max).length
    
    return res
  }

  let [mapData,setMapData] = useState([])


  const mapDataFunction = async () => {
    const newmapdata = [...mapData]
    let keysdata = converToRange(grouped_keys_data);
    for (let j = 0; j < keysdata.length; j++) {
      const element = keysdata[j].nft_series;

      const minval = parseInt(element.split("-")[0]) == 1 ? 0 : parseInt(element.split("-")[0])
      const maxval = parseInt(element.split("-")[1])
      const elementStr = `${minval}-${maxval}`
      
      if (element.split("-").length == 2){
        newmapdata.push({nft_series: elementStr, sum: filtered_wallet_values(element)})
      }
    }

    setMapData(newmapdata)
  }

  // console.log("Grouped collectorsCount =>", (grouped_keys_data[grouped_keys_data.length-1]));
  useEffect(() => {
    mapDataFunction()
  },[])
  

  let collectorsCount=[];
  let nftSeries=[];
  let collections_data = mapData;
  if(collections_data){
    let graphResult ={}
    for (let index = 0; index < collections_data.length; index++) {
      const element = collections_data[index];
      graphResult[element.nft_series] ||=0
      graphResult[element.nft_series] +=parseInt(element.sum)
    }

    for (const key in graphResult) {
      nftSeries.push(key);
      collectorsCount.push(graphResult[key]);
    }
  }

  // nftSeries = nftSeries.slice(0, 10)
  // collectorsCount = collectorsCount.slice(0, 10)
  // console.log("collectorsCount ===>",nftSeries)
  const data = {
    labels: nftSeries,
    datasets: [{
      backgroundColor: (context) => {
        const ctx = context.chart.ctx;
        const gradient = ctx.createLinearGradient(0, 0, 0, 200);
        gradient.addColorStop(0, "rgba(0, 209, 222, 1)");
        gradient.addColorStop(1, "rgba(0, 209, 222, .15)");
        return gradient;
      },
      label: 'Wallet Value in USD',
      data: collectorsCount
    }]
  };
  
  return(
    <>
      <section>
        
        <div className="mt-4">
        {heading.map((table,index) => <PolyientHeading key={index}  head={table.head} para={table.para}/>)}
        </div>
        <div className="mt-5" style={{height: "400px"}}>
          <Bar fill="#00FFFF" data={data} options={options} />
        </div>
      </section>
    </>
  )
}
export default WalletHolders;
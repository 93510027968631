import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {Table} from "react-bootstrap";
import CollectionTable from "../../components/collectionTable";
import Pagination from 'react-responsive-pagination';
import PolyientHeading from "../polyientHeading";
import {getTopVolumeNFTProjects} from '../../services/actions/api';

const NftCollection = (props) => {
  const {topVolumeNFTProjects} = props;
  let volumesData = [];
  useEffect(() => {
    props.getTopVolumeNFTProjects();
  }, []);
  console.log('topVolumeNFTProjects', topVolumeNFTProjects);
  
  
  if (Array.isArray(topVolumeNFTProjects.data)){
    volumesData = topVolumeNFTProjects.data.map((item, index) => {
      return {
        data1: item.name,
        data2: item.sales_volume,
        contract_address: item.token_contract,
      };
    })
  }
  
  

  return(
    <>
      <section>
          <div className="mt-4">
            <Table  hover  responsive>
              <thead>
                <tr>
                  <th className="table-heading">Collection</th>
                  <th></th>
                  <th className="table-heading">Total Sales - USD </th>
                </tr>
              </thead>
            <tbody>
              {volumesData.map((table,index) => <CollectionTable key={index} contract_address={table.contract_address} data1={table.data1} data2={table.data2} />)}
            </tbody>
          </Table>  
          </div>
      </section>
    </>
  )
}
const mapStateToProps = (state) => ({
  page: state.page,
  topVolumeNFTProjects: state.topVolumeNFTProjects
})

const mapDispatchToProps = {
  getTopVolumeNFTProjects
}

export default connect(mapStateToProps, mapDispatchToProps)(NftCollection);
import React from 'react'
import Footer from '../components/footer';

import Header from '../components/header';


export const LayoutOne=(props)=>{
  return (
    <>
      <Header/>
         {props.children}
       <Footer/>  
    </>
  )
}
